import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter, useHistory } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import UserInfo from 'components/UserInfo';
import {
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import { toggleCollapsedNav, updateWindowWidth } from 'actions/Setting';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/styles';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import ChatIcon from '@material-ui/icons/Chat';
import SideBarContent from './SideBarContent';

const useStyles = makeStyles(() => ({
  list: {
    padding: '30px 24px',
  },

  listItem: {
    '& .MuiListItemIcon-root': {
      marginTop: 5,
      minWidth: 'auto',
    },
    '& .MuiListItemText-root': {
      marginLeft: 20,
    },
  },

  listItemCollapsed: {
    '.app-sidebar-content:not(:hover) &': {
      width: 40,
      height: 40,
      padding: 3,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiListItemIcon-root': {
        marginTop: 0,
      },
    },
    '& .MuiListItemIcon-root': {
      marginTop: 5,
      minWidth: 'auto',
    },
    '& .MuiListItemText-root': {
      marginLeft: 20,
      '.app-sidebar-content:not(:hover) &': {
        display: 'none',
      },
    },
  },
}));

const SideBar = () => {
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();
  const { navCollapsed, drawerType, width, navigationStyle } = useSelector(
    ({ settings }) => settings
  );

  const authUserInfo = useSelector(({ auth }) => auth.authUserInfo);

  const { location } = window;
  const pathFullMenu = location.pathname;
  const pathMenuPos = pathFullMenu.indexOf('/', 6);
  let pathFull = pathFullMenu;
  if (pathMenuPos > 0) {
    pathFull = pathFullMenu.substring(0, pathMenuPos);
  }

  useEffect(() => {
    window.addEventListener('resize', () => {
      dispatch(updateWindowWidth(window.innerWidth));
    });
  }, [dispatch]);

  const onToggleCollapsedNav = (e) => {
    const val = !navCollapsed;
    dispatch(toggleCollapsedNav(val));
  };

  let drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? 'd-xl-flex'
    : drawerType.includes(COLLAPSED_DRAWER)
    ? ''
    : 'd-flex';
  let type = 'permanent';
  if (
    drawerType.includes(COLLAPSED_DRAWER) ||
    (drawerType.includes(FIXED_DRAWER) && width < 1200)
  ) {
    type = 'temporary';
  }

  if (navigationStyle === HORIZONTAL_NAVIGATION) {
    drawerStyle = '';
    type = 'temporary';
  }

  return (
    <div className={`app-sidebar d-none ${drawerStyle}`}>
      <Drawer
        className="app-sidebar-content"
        variant={type}
        open={type.includes('temporary') ? navCollapsed : true}
        onClose={onToggleCollapsedNav}
        classes={{
          paper: 'side-nav',
        }}
      >
        <UserInfo />
        <SideBarContent />

        <List
          className={classes.list}
          disablePadding
          hidden={
            pathFull !== '/app/cob' || authUserInfo?.perfil === 'NEGOCIADOR'
          }
        >
          <ListItem
            alignItems="flex-start"
            button
            style={{
              backgroundColor: '#fef9e7',
              color: '#f4d03f',
              borderRadius: '10px',
            }}
            className={
              drawerType.includes(FIXED_DRAWER)
                ? classes.listItem
                : classes.listItemCollapsed
            }
            component={Link}
            to="/app/cob/suporte-tecnico"
          >
            <ListItemIcon style={{ color: '#f4d03f' }}>
              <ChatIcon />
            </ListItemIcon>
            <ListItemText primary="Suporte" />
          </ListItem>
        </List>

        <List
          className={classes.list}
          disablePadding
          hidden={pathFull !== '/app/config' && pathFull !== '/app/backof'}
        >
          <ListItem
            alignItems="flex-start"
            button
            component="a"
            onClick={() => {
              window.open(
                `https://ajuda.cobcloud.com.br/categorias/atualizacoes/`,
                '_blank'
              );
            }}
            style={{
              borderRadius: '10px',
              backgroundColor:
                pathFull === '/app/config' ? '#FFF1D2' : '#E3F9FF',
              color: pathFull === '/app/config' ? '#D36F1A' : '#0356AF',
            }}
            className={
              drawerType.includes(FIXED_DRAWER)
                ? classes.listItem
                : classes.listItemCollapsed
            }
          >
            <ListItemIcon
              style={{
                color: pathFull === '/app/config' ? '#D36F1A' : '#0356AF',
              }}
            >
              <RotateLeftIcon />
            </ListItemIcon>
            <ListItemText primary="Atualizações" />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
};

export default withRouter(SideBar);
