export const URL_BOLETO_AWS_S3 =
  'https://cobcloud-boletos.s3-sa-east-1.amazonaws.com/';

export const URL_BOLETO_COB_S3 = 'https://boletos.cobcloud.com.br/';

//export const URL_BOLETO_NOVO = 'https://boletos.cob.cloud/pdf/';

export const URL_PIX = 'https://pay.cobcloud.com.br/pix/';

export const URL_FATURAS = 'https://faturas.cob.cloud';

export const URL_ALEGA = 'https://alega.cob.cloud';
