/* eslint eqeqeq: 0 */

import React, { useRef, useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';

import {
  Drawer,
  IconButton,
  TextField,
  Button,
  Divider,
  Switch,
  Typography,
  Grid,
  Box,
  Menu,
  MenuItem,
  ListItemText,
  Fade,
} from '@material-ui/core';

import { green } from '@material-ui/core/colors';

import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import PhoneIcon from '@material-ui/icons/Phone';
import PhonePausedIcon from '@material-ui/icons/PhonePaused';

import { setReduxDadosDiscador } from 'actions/Store';

import api from 'services/api';
import { getHashEmpresa } from 'util/cobUtils';
import SnackbarNotification from 'components/SnackbarNotification';
import { io } from 'socket.io-client';

import {
  createOlos,
  terminateWorker,
  OlosAgentWS,
  olosOn,
  olosOff,
} from '@olostecnologia/olosagentsdk';

const GreenSwitch = withStyles({
  switchBase: {
    color: green[300],
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    marginTop: '5px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

const useStyles = makeStyles(() => ({
  listItemText: {
    fontSize: '12px',
  },
}));

const PainelDiscador = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const authUserInfo = useSelector(({ auth }) => auth.authUserInfo);
  const dadosDiscador = useSelector(({ store }) => store.dadosDiscador);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertInfo, setAlertInfo] = useState();
  const [alertInfoTipo, setAlertInfoTipo] = useState();

  const [loading, setLoading] = useState(false);
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [ativaDiscador, setAtivaDiscador] = useState(false);
  const [listaMotivosPausa, setListaMotivosPausa] = useState([]);
  const [listaCampanhas, setListaCampanhas] = useState([]);
  const [logado, setLogado] = useState(false);
  const [pausado, setPausado] = useState(dadosDiscador.pausado);
  const [statusAgente, setStatusAgente] = useState('');
  const [msgStatusAgente, setMsgStatusAgente] = useState('');
  const [corStatusAgente, setCorStatusAgente] = useState('text-success');
  const [corBotao, setCorBotao] = useState('green');
  const [inicializarDiscador, setInicializarDiscador] = useState(false);
  const [counter, setCounter] = useState(0);
  const [callfilename, setCallfilename] = useState('');

  const [retornoDevedor, setRetornoDevedor] = useState('');
  const [retornoProcesso, setRetornoProcesso] = useState('');
  const [retornoTelefone, setRetornoTelefone] = useState('');
  const [retornoCampanhaLogadaNome, setRetornoCampanhaLogadaNome] =
    useState('');
  const [retornoCampanhaLogadaId, setRetornoCampanhaLogadaId] = useState('');
  const [campanhaId, setCampanhaId] = useState('');
  const [bloqueiaComboCampanha, setBloqueiaComboCampanha] = useState(false);

  const [ramalTopo, setRamalTopo] = useState('');
  const [ramalRodape, setRamalRodape] = useState('');
  const [idAgente, setIdAgente] = useState('');
  const [isModoManual, setIsModoManual] = useState(false);
  const [isModoTabulando, setIsModoTabulando] = useState(false);

  const [waitingToReconnect, setWaitingToReconnect] = useState(null);
  const [waitingToReconnectOlos, setWaitingToReconnectOlos] = useState(null);

  const { register, setValue, getValues } = useForm();

  const clientRef = useRef(null);
  const callIdRef = useRef(null);
  const ramal3CPlusRef = useRef(null);
  let jaPausado = 0;

  const [anchorEl, setAnchorEl] = useState(null);

  const toggleCustomizer = () => {
    setDrawerStatus(!drawerStatus);
  };
  const closeCustomizer = () => {
    setDrawerStatus(false);
  };

  const abrirMenuMaisOpcoes = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const fecharMenuMaisOpcoes = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (authUserInfo) {
      listarMotivosPausa();

      if (authUserInfo.disc_gateway == 'Vonix') {
        setRamalTopo(authUserInfo.ramal);
        conectar_vonix();
      } else if (authUserInfo.disc_gateway == 'JDN') {
        setRamalTopo(authUserInfo.ramal);
        if (authUserInfo.ramal) conectar_jdn();
      } else if (authUserInfo.disc_gateway == 'ExpertVoice') {
        setRamalRodape(authUserInfo.ramal);
        conectar_expertvoice();
      } else if (authUserInfo.disc_gateway == '3CPlus') {
        infoAgent3CPlus();
        conectar_3cplus();
      } else if (authUserInfo.disc_gateway == 'Olos') {
        conectar_olos();
      }
    }
  }, [authUserInfo]);

  /* PAUSA */
  async function pausarAgente() {
    if (authUserInfo.disc_gateway == 'Vonix') pausarAgenteVonix();
    else if (authUserInfo.disc_gateway == 'JDN') pausarAgenteJDN();
    else if (authUserInfo.disc_gateway == 'ExpertVoice')
      pausarAgenteExpertVoice();
    else if (authUserInfo.disc_gateway == '3CPlus') pausarAgente3CPlus();
    else if (authUserInfo.disc_gateway == 'Olos') pausarAgenteOlos();
  }

  /* DESPAUSA */
  async function despausarAgente() {
    if (authUserInfo.disc_gateway == 'Vonix') despausarAgenteVonix();
    else if (authUserInfo.disc_gateway == 'JDN') despausarAgenteJDN();
    else if (authUserInfo.disc_gateway == 'ExpertVoice')
      despausarAgenteExpertVoice();
    else if (authUserInfo.disc_gateway == '3CPlus') {
      if (!isModoManual) despausarAgente3CPlus();
      else sairModoManualAgente3CPlus();
    } else if (authUserInfo.disc_gateway == 'Olos') {
      if (!isModoManual) despausarAgenteOlos();
      else sairModoManualAgenteOlos();
    }
  }

  useEffect(() => {
    dispatch(setReduxDadosDiscador({ ...dadosDiscador, pausado }));
  }, [pausado]);

  useEffect(() => {
    if (authUserInfo.disc_gateway == 'Vonix') {
      if (ativaDiscador) infoLoginVonix();
      else if (!ativaDiscador) {
        if (logado) deslogarAgenteVonix();
      }
    }
    // JDN
    else if (authUserInfo.disc_gateway == 'JDN') {
      if (ativaDiscador) {
        infoLoginJDN();
      }
    }
    // ExpertVoice
    else if (authUserInfo.disc_gateway == 'ExpertVoice') {
      if (ativaDiscador) {
        loginAgenteExpertVoice();
      } else if (!ativaDiscador) {
        deslogarAgenteExpertVoice();
      }
    }
  }, [ativaDiscador]);

  async function cadastrar_pausa(codPausa, tipo) {
    let descPausa = '';
    const pausaSelecionada = listaMotivosPausa.find(
      (el) => el.codigo == codPausa
    );
    if (pausaSelecionada) descPausa = pausaSelecionada.descricao;

    const hashEmpresa = getHashEmpresa();
    const data = {};
    data.colaborador = authUserInfo?.ac_login;
    data.motivo = descPausa;
    data.tipo = tipo;

    const data2 = JSON.stringify(data, null, 2);
    await api.post(`/pausa_usuario/${hashEmpresa}`, {
      data2,
    });
  }

  async function discar() {
    let fone = getValues('tx_fone');

    let ramal = '';
    if (authUserInfo) ramal = authUserInfo.ramal;

    if (fone != '') {
      fone = fone.trim().replace(/[^0-9]+/g, '');

      if (authUserInfo.disc_gateway == 'Vonix') {
        const pabxLogin = authUserInfo.pabx_login;
        let idFila = '';
        if (pabxLogin.indexOf('Bearer') > -1) idFila = null;
        clientRef.current.doDial(fone, fone, idFila, '1', '12345');
      } else if (authUserInfo.disc_gateway == 'ExpertVoice') {
        const fone_ddd = fone.substring(0, 2);
        const fone_numero = fone.substring(2, fone.length);

        clientRef.current.send(
          `DISCAR(12345;${fone_ddd};${fone_numero};;;;;DAC;;;${ramal})`
        );
      } else if (authUserInfo.disc_gateway == '3CPlus') {
        const hashEmpresa = getHashEmpresa();
        await api.get(`/pabx_discar/${hashEmpresa}?fone=${fone}`);
      } else if (authUserInfo.disc_gateway == 'Olos') {
        const fone_ddd = fone.substring(0, 2);
        const fone_numero = fone.substring(2, fone.length);

        if (!campanhaId) {
          setAlertInfo('Operador sem campanha para discagem manual!');
          setAlertInfoTipo('mb-3 bg-danger');
          setOpenAlert(true);
          return;
        }

        if (!isModoManual) {
          clientRef.current.manualCallStateRequest();
        }

        clientRef.current.sendManualCallRequest(
          fone_ddd,
          fone_numero,
          campanhaId
        );
      }
    }
  }

  async function derrubar() {
    if (authUserInfo.disc_gateway == 'Vonix') {
      const pabxLogin = authUserInfo.pabx_login;
      let idFila = '';
      if (pabxLogin.indexOf('Bearer') > -1) idFila = null;
      clientRef.current.doHangUp(idFila);
    } else if (authUserInfo.disc_gateway == 'ExpertVoice') {
      let ramal = '';
      if (authUserInfo) ramal = authUserInfo.ramal;

      clientRef.current.send(`DESLIGAR(${ramal};)`);

      setAlertInfo('Desligando...');
      setAlertInfoTipo('mb-3 bg-danger');
      setOpenAlert(true);
    } else if (authUserInfo.disc_gateway == '3CPlus') {
      await api.post(`/pabx_desligar_call?callid=${callIdRef.current}`);
    } else if (authUserInfo.disc_gateway == 'Olos') {
      if (callIdRef.current) clientRef.current.hangupRequest(callIdRef.current);

      if (isModoManual) clientRef.current.endManualCallStateRequest();
    }
  }

  async function localizarProcesso(
    callfilename,
    retProcesso,
    retFone,
    idGrupo
  ) {
    if (retProcesso) {
      const hashEmpresa = getHashEmpresa();
      const response = await api.get(
        `/localizar_processo/${hashEmpresa}?processo=${retProcesso}`
      );
      const data = await response.data.rows;
      if (data.length > 0) {
        const uid = data[0].iddev;
        history.push(`/app/cob/processo/${uid}`, {
          id: uid,
          fone: retFone,
          fileNameChamada: callfilename,
          idGrupo,
        });
      }
    }
  }

  async function localizarProcessoManual() {
    if (retornoProcesso) {
      const hashEmpresa = getHashEmpresa();
      const response = await api.get(
        `/localizar_processo/${hashEmpresa}?processo=${retornoProcesso}`
      );
      const data = await response.data.rows;
      if (data.length > 0) {
        const uid = data[0].iddev;

        if (
          authUserInfo.disc_gateway == 'Vonix' ||
          authUserInfo.disc_gateway == 'ExpertVoice' ||
          authUserInfo.disc_gateway == '3CPlus'
        ) {
          history.push(`/app/cob/processo/${uid}`, {
            id: uid,
            fone: retornoTelefone,
          });
        } else if (authUserInfo.disc_gateway == 'JDN') {
          history.push(`/app/cob/processo/${uid}`, {
            id: uid,
            fone: retornoTelefone,
            fileNameChamada: callfilename,
          });
        }
      }
    }
  }

  const handleLogarCampanha = () => {
    if (authUserInfo.disc_gateway == '3CPlus') {
      logarCampanha3CPlus();
    }

    if (authUserInfo.disc_gateway == 'JDN') {
      logarCampanhaJDN();
    }
  };

  useEffect(() => {
    if (logado == false) {
      setCorStatusAgente('text-danger');
      setCorBotao('red');
    } else if (logado == true) {
      setCorStatusAgente('text-success');
      setCorBotao('green');
    }
    if (pausado == true) {
      setCorStatusAgente('text-warning');
      setCorBotao('orange');
    }
  }, [logado]);

  useEffect(() => {
    if (logado == false) {
      setCorStatusAgente('text-danger');
      setCorBotao('red');
    } else if (logado == true) {
      setCorStatusAgente('text-success');
      setCorBotao('green');
    }

    if (pausado == true) {
      setCorStatusAgente('text-warning');
      setCorBotao('orange');
    }

    if (pausado == false) {
      setCounter(0);
    }

    if (pausado == true) {
      const timer = setInterval(() => {
        setCounter((prevCount) => prevCount + 1);
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [pausado]);

  const horaPausa = () => {
    if (pausado) {
      const date = new Date(0);
      date.setSeconds(counter);
      const timeString = date.toISOString().substr(11, 8);
      return ` - ${timeString}`;
    }
    return '';
  };

  async function listarMotivosPausa() {
    try {
      const hashEmpresa = getHashEmpresa();
      const discador = authUserInfo?.disc_gateway;

      const flag = 'MOT_PAUSAS';
      const response = await api.get(`/tabelas/${hashEmpresa}?flag=${flag}`);
      let data = await response.data.rows;
      if (data.length > 0) {
        data = data.filter(
          (el) => el.pabx_plataforma == discador || !el.pabx_plataforma
        );
      }

      setListaMotivosPausa(data);
    } catch (error) {}
  }

  // ********************************************************************************************************************
  // VONIX

  async function conectar_vonix() {
    if (authUserInfo) {
      const { ramal } = authUserInfo;
      let url = authUserInfo.pabx_url;
      const pabxLogin = authUserInfo.pabx_login;

      if (ramal && url) {
        if (pabxLogin.indexOf('Bearer') > -1) {
          let pabx_token = pabxLogin;
          pabx_token = pabx_token.replace('Bearer ', '');

          // nova versao
          url = `${url}?token=${pabx_token}`;

          const client = window.ativaVonixCC(url, ramal);
          clientRef.current = client;
          window.client = client;
        } else {
          // antiga
          url = url.replace('http://', '');
          url = url.replace('https://', '');

          const client = window.ativaVonix(url, ramal);
          clientRef.current = client;
          window.client = client;
        }
      }
    }
  }

  // monitor de estados
  useEffect(() => {
    if (clientRef.current && authUserInfo.disc_gateway == 'Vonix') {
      const pabxLogin = authUserInfo.pabx_login;

      clientRef.current.onConnect(function (date) {
        setInicializarDiscador(true);

        setTimeout(() => {
          infoLoginVonix();
        }, 1000);
      });

      clientRef.current.onPause(function (date, reason) {
        setStatusAgente('PAUSADO');
        setPausado(true);

        cadastrar_pausa(reason, 'Pausa');
      });

      clientRef.current.onUnpause(function (date, reason, duration) {
        setStatusAgente('ONLINE');
        setPausado(false);

        cadastrar_pausa(reason, 'Despausa');
      });

      clientRef.current.onLogin(function (date, location) {
        setStatusAgente('ONLINE');
        setLogado(true);
      });

      clientRef.current.onLogoff(function (date, location, duration) {
        setStatusAgente('OFFLINE');
        setLogado(false);
      });

      clientRef.current.onStatus(function (
        status,
        location,
        since,
        status_data
      ) {
        setStatusAgente(status);
        if (status == 'ONLINE') {
          setLogado(true);
        } else if (status == 'OFFLINE') setLogado(false);
        else if (status == 'PAUSED') {
          setLogado(true);
          setPausado(true);

          if (status_data) {
            const codPausa = status_data.pause_reason;
            let pausaSelecionada = {};
            let id_uuid_pausa = '';
            if (codPausa != '' && codPausa != null) {
              pausaSelecionada = listaMotivosPausa.find(
                (el) => el.codigo == codPausa
              );
              if (pausaSelecionada) id_uuid_pausa = pausaSelecionada.id_uuid;
              setValue('tx_pausa', id_uuid_pausa);
            }
          }
        }
      });

      clientRef.current.onReceive(function (
        call_id,
        date,
        queue,
        from,
        to,
        callfilename,
        contact_name,
        action_id,
        additional_data
      ) {
        // console.log('onReceive..');
        // console.log('additional_data..', additional_data);
        // console.log('action_id..', action_id);

        let retorno_processo = additional_data.contactid;
        if (!retorno_processo) retorno_processo = action_id;
        const retorno_contato = contact_name;
        const retorno_fone = from;

        let retorno_callfilename = '';
        if (pabxLogin.indexOf('Bearer') > -1) retorno_callfilename = call_id;
        else retorno_callfilename = callfilename;

        if (retorno_processo) {
          retorno_processo = retorno_processo.replace('-', '/');

          if (retorno_processo.indexOf('.') > 0)
            retorno_processo = retorno_processo.substring(
              0,
              retorno_processo.indexOf('.')
            );
        }

        setRetornoDevedor(retorno_contato);
        setRetornoProcesso(retorno_processo);
        setRetornoTelefone(retorno_fone);
        // setValue('tx_retorno_processo', retorno_processo);
        // setValue('tx_retorno_contato', retorno_contato);
        // setValue('tx_retorno_fone', retorno_fone);

        if (ativaDiscador && retorno_callfilename)
          localizarProcesso(
            retorno_callfilename,
            retorno_processo,
            retorno_fone,
            ''
          );
      });
    }
  }, [ativaDiscador]);

  // pausa
  async function pausarAgenteVonix() {
    const idPausa = getValues('tx_pausa');
    let pausaSelecionada = {};
    let codPausa = '';
    if (idPausa != '' && idPausa != null) {
      pausaSelecionada = listaMotivosPausa.find((el) => el.id_uuid == idPausa);
      if (pausaSelecionada) codPausa = pausaSelecionada.codigo;
    }
    if (codPausa != '') {
      clientRef.current.doPause(codPausa);
    }
  }

  async function pausarAgenteAcionamentoVonix() {
    let codPausa = '3';
    if (getHashEmpresa() == 'mt93ol') codPausa = '5';
    else if (getHashEmpresa() == 'lt93cb') codPausa = '7';
    else if (getHashEmpresa() == 'lg13cb') codPausa = '7';

    if (authUserInfo.ramal !== '' && authUserInfo.disc_gateway == 'Vonix') {
      if (clientRef.current) {
        if (pausado) clientRef.current.doUnpause('');
        else clientRef.current.doPause(codPausa);
      }
    }
  }

  // despausa
  async function despausarAgenteVonix() {
    clientRef.current.doUnpause('');
  }

  async function infoLoginVonix() {
    if (clientRef.current) clientRef.current.doStatus('');
  }

  async function deslogarAgenteVonix() {
    let ramal = '';
    if (authUserInfo) ramal = authUserInfo.ramal;

    const response = await api.post(
      `/pabx_logoff/${getHashEmpresa()}?ramal=${ramal}&campanha=${0}`
    );
    if (response.status <= 400) {
      setLogado(false);
    }
  }

  // ********************************************************************************************************************
  // 3CPLUS

  async function conectar_3cplus() {
    let ramal = '';
    let url = '';
    if (authUserInfo) {
      ramal = authUserInfo.ramal;
      url = authUserInfo.pabx_url;
    }

    if (ramal != '' && url != '') {
      try {
        const urlSocket = 'https://socket.3c.fluxoti.com';
        const socket = io(urlSocket, {
          transports: ['websocket'],
          query: {
            token: ramal,
          },
        });

        window.client = socket;
        clientRef.current = socket;
      } catch (error) {
        console.log('Erro WebSocket!!! ', error);
      }
    }
  }

  // monitor de estados
  useEffect(() => {
    if (clientRef.current && authUserInfo.disc_gateway == '3CPlus') {
      listarCampanhas3CPlus();

      const handleCallWasConnected = (event) => {
        const idAgenteEvento = event.agent.id;
        if (idAgente == idAgenteEvento) {
          setMsgStatusAgente('Em Andamento');
          setPausado(false);
          setStatusAgente('ONLINE');
          setCorBotao('blue');

          const { phone: numero } = event.call;
          callIdRef.current = event.call.id;
          const telefoneFormatado = numero.substring(2);

          if (event.call.call_mode !== 'manual') {
            setRetornoTelefone(telefoneFormatado);
            if (event.mailing)
              setRetornoDevedor(event.mailing.data?.name || '');
            setRetornoProcesso(event.call.identifier);

            if (event.call.identifier && event.call.id && ativaDiscador) {
              localizarProcesso(
                event.call.id,
                event.call.identifier,
                telefoneFormatado,
                ''
              );
            }
          }
        }
      };

      const handleAgentEnteredWorkBreak = (event) => {
        const idAgenteEvento = event.agent.id;
        if (idAgente == idAgenteEvento) {
          setMsgStatusAgente('');
          setPausado(true);
          setStatusAgente('PAUSADO');
          setLoading(false);
        }
      };

      const handleAgentLeftWorkBreak = (event) => {
        const idAgenteEvento = event.agent.id;
        if (idAgente == idAgenteEvento) {
          setPausado(false);
          setStatusAgente('ONLINE');
          setLoading(false);
        }
      };

      const handleAgentIsConnected = async (event) => {
        /*
        STATUS_OFFLINE = 0
        STATUS_IDLE = 1
        STATUS_ON_CALL = 2
        STATUS_ACW = 3
        STATUS_ON_MANUAL_CALL = 4
        STATUS_ON_MANUAL_CALL_CONNECTED = 5
        STATUS_ON_WORK_BREAK = 6
        STATUS_ON_CONSULT = 7
        STATUS_ON_MANUAL_CALL_ACW = 21
        STATUS_MANUAL_CALL_CONNECTED = 22
        STATUS_CONSULT_HOLD = 1
        STATUS_CONSULT_CONNECTED = 2
        */

        const idAgenteEvento = event.agent.id;
        //console.log(idAgente, '....', idAgenteEvento);
        if (idAgente == idAgenteEvento) {
          setLoading(false);

          if (event.call) {
            setMsgStatusAgente('Em Andamento');
            setCorBotao('blue');
            callIdRef.current = event.call.id;
          }

          if (event.campaignId) {
            const id = event.campaignId;
            setCampanhaId(id);
            setValue('tx_campanhas_ativas', id);

            //setAtivaDiscador(true);
            //if (event.status === 0) setAtivaDiscador(false);

            if (event.status !== 6 && event.status !== 4) {
              setLogado(true);
              setStatusAgente('ONLINE');
              setCorBotao('green');
            }

            if (event.status === 6) {
              const idPausa = event.work_break.id;
              setLogado(true);
              setPausado(true);
              setStatusAgente('PAUSADO');

              if (listaMotivosPausa) {
                const pausaSelecionada = listaMotivosPausa.find(
                  (el) => el.codigo == idPausa
                );
                if (pausaSelecionada) {
                  setValue('tx_pausa', pausaSelecionada.id_uuid);
                }
              }
            }

            if (event.status === 2 || event.status === 5) {
              setCorBotao('blue');
              callIdRef.current = event.call?.id;

              if (event.status === 5) {
                setMsgStatusAgente('Em Ligação');
              } else if (event.status === 2) {
                setMsgStatusAgente('Em Andamento');
              }
            }

            if (event.status === 4) {
              setIsModoManual(true);
              setCorBotao('yellow');
            } else {
              setIsModoManual(false);
            }

            setBloqueiaComboCampanha(true);
          }
        }
      };

      const handleCallWasFinished = (event) => {
        const idAgenteEvento = event.call.agent;
        if (idAgente == idAgenteEvento) {
          setMsgStatusAgente('');
          setCorBotao('green');
        }
      };

      const callManualCallWasAnswered = (event) => {
        const idAgenteEvento = event.call.agent;
        if (idAgente == idAgenteEvento) {
          setMsgStatusAgente('Em Ligação');
          setCorBotao('blue');
          callIdRef.current = event.call.id;
        }
      };

      const callAgentIsIdle = async (event) => {
        const idAgenteEvento = event.agent.id;
        if (idAgente == idAgenteEvento) {
          const idCampanha = event.campaignId;
          const campanhaSelecionada = listaCampanhas.find(
            (item) => item.campanha_id == idCampanha
          );
          setLogado(true);
          setStatusAgente('ONLINE');
          setMsgStatusAgente('');
          setBloqueiaComboCampanha(true);
          setRetornoCampanhaLogadaNome(
            campanhaSelecionada ? campanhaSelecionada.campanha_nome : null
          );

          if (event.agent.status === 4) {
            await api.post(`/pabx_sair_modo_manual`);
          }
        }
      };

      const handleAgentLoginFailed = () => {
        setAlertInfo('Erro ao logar na campanha!');
        setAlertInfoTipo('mb-3 bg-danger');
        setOpenAlert(true);
      };

      const handleAgentLeftManual = (event) => {
        const idAgenteEvento = event.agent.id;
        if (idAgente == idAgenteEvento) {
          setIsModoManual(false);
          setCorBotao('green');
        }
      };

      const handleAgentEnteredManual = (event) => {
        const idAgenteEvento = event.agent.id;
        if (idAgente == idAgenteEvento) {
          setIsModoManual(true);
          setCorBotao('yellow');
        }
      };

      const handleCallHistoryCreated = (event) => {
        const idAgenteEvento = event.callHistory.agent.id;
        if (idAgente == idAgenteEvento) {
          if (event.callHistory.call_mode !== 'manual') {
            // só para chamadas do discador
            const idCallDownload = event.callHistory._id;
            const tempoFalado = event.callHistory.speaking_time || 0;

            const numeroFone = event.callHistory.number;

            const ramal =
              ramal3CPlusRef.current && authUserInfo.disc_gateway == '3CPlus'
                ? ramal3CPlusRef.current
                : authUserInfo.ramal;

            let usuario = '';
            if (authUserInfo) usuario = authUserInfo.ac_login;

            const data = {};
            data.fone = numeroFone;
            data.ramal = ramal;
            data.operador = usuario;

            const bodyData = {
              callId: callIdRef.current,
              idCallDownload,
              processo: event.callHistory.mailing_data.identifier,
              fone: data,
            };
            if (tempoFalado > 0 && callIdRef.current)
              handleQualificaLigacao(bodyData);

            callIdRef.current = null;
          }
        }
      };

      clientRef.current.on('connect', function () {
        setInicializarDiscador(true);
      });
      clientRef.current.on('connect_error', function () {
        setInicializarDiscador(false);
      });

      clientRef.current.on('call-was-connected', handleCallWasConnected);
      clientRef.current.on('call-was-finished', handleCallWasFinished);
      clientRef.current.on(
        'manual-call-was-answered',
        callManualCallWasAnswered
      );
      clientRef.current.on(
        'agent-entered-work-break',
        handleAgentEnteredWorkBreak
      );
      clientRef.current.on('agent-left-work-break', handleAgentLeftWorkBreak);
      clientRef.current.on('agent-is-idle', callAgentIsIdle);
      clientRef.current.on('agent-is-connected', handleAgentIsConnected);
      clientRef.current.on('agent-login-failed', handleAgentLoginFailed);
      clientRef.current.on('agent-left-manual', handleAgentLeftManual);
      clientRef.current.on('agent-entered-manual', handleAgentEnteredManual);
      clientRef.current.on(
        'call-history-was-created',
        handleCallHistoryCreated
      );

      return () => {
        if (clientRef.current) {
          clientRef.current.off('call-was-connected', handleCallWasConnected);
          clientRef.current.off('call-was-finished', handleCallWasFinished);
          clientRef.current.off(
            'manual-call-was-answered',
            callManualCallWasAnswered
          );
          clientRef.current.off(
            'agent-entered-work-break',
            handleAgentEnteredWorkBreak
          );
          clientRef.current.off(
            'agent-left-work-break',
            handleAgentLeftWorkBreak
          );
          clientRef.current.off('agent-is-idle', callAgentIsIdle);
          clientRef.current.off('agent-is-connected', handleAgentIsConnected);
          clientRef.current.off('agent-login-failed', handleAgentLoginFailed);
          clientRef.current.off('agent-left-manual', handleAgentLeftManual);
          clientRef.current.off(
            'agent-entered-manual',
            handleAgentEnteredManual
          );
          clientRef.current.off(
            'call-history-was-created',
            handleCallHistoryCreated
          );

          // clientRef.current.removeListener('call-was-connected', handleCallConnected);
        }
      };
    }
  }, [ativaDiscador, idAgente]);

  async function logarCampanha3CPlus() {
    try {
      const idCampanha = getValues('tx_campanhas_ativas');
      if (!idCampanha) return;

      await api.post(`/pabx_login/${getHashEmpresa()}?campanha=${idCampanha}`);
    } catch (error) {
      setAlertInfo('Erro ao logar na campanha!');
      setAlertInfoTipo('mb-3 bg-danger');
      setOpenAlert(true);
    }
  }

  // pausa
  async function pausarAgente3CPlus() {
    const idPausa = getValues('tx_pausa');
    let pausaSelecionada = {};
    let codPausa = '';
    let usuario = '';
    let descPausa = '';

    if (authUserInfo) {
      usuario = authUserInfo.ac_login;
    }

    if (idPausa) {
      pausaSelecionada = listaMotivosPausa.find((el) => el.id_uuid == idPausa);
      if (pausaSelecionada) {
        codPausa = pausaSelecionada.codigo;
        descPausa = pausaSelecionada.descricao;
      }
    }

    try {
      if (codPausa != '') {
        setLoading(true);

        const response = await api.get(
          `/pabx_pausa/${getHashEmpresa()}?usuario=${usuario}&pausa=${codPausa}&descpausa=${descPausa}`
        );
        if (response.status <= 400) {
          const { data } = response;
          if (data.Response == 'Success') {
            setPausado(true);
            setStatusAgente('PAUSADO');
          }
        }
      }
    } catch (error) {
      setLoading(false);
    }
  }

  // despausa
  async function despausarAgente3CPlus() {
    let usuario = '';
    if (authUserInfo) {
      usuario = authUserInfo.ac_login;
    }

    setLoading(true);

    try {
      const response = await api.get(
        `/pabx_despausa/${getHashEmpresa()}?usuario=${usuario}`
      );
      if (response.status <= 400) {
        const { data } = response;
        if (data.Response == 'Success') {
          setPausado(false);
          setLogado(true);
          setStatusAgente('ONLINE');
          setBloqueiaComboCampanha(false);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  }

  async function listarCampanhas3CPlus() {
    const response = await api.get(
      `/pabx_listar_campanhas/${getHashEmpresa()}?plataforma=3CPlus`
    );
    if (response.status <= 400) {
      setListaCampanhas(response.data);
    }
  }

  useEffect(() => {
    if (authUserInfo.disc_gateway == '3CPlus') {
      statusAgent3CPlus();
    }
  }, [drawerStatus]);

  useEffect(() => {
    if (clientRef.current && authUserInfo.disc_gateway == '3CPlus') {
      const campanhaSelecionada = listaCampanhas.find(
        (item) => item.campanha_id == campanhaId
      );
      if (campanhaSelecionada) {
        setRetornoCampanhaLogadaNome(campanhaSelecionada.campanha_nome);
      }
    }
  }, [campanhaId, logado]);

  async function statusAgent3CPlus() {
    if (authUserInfo.disc_gateway == '3CPlus') {
      await api.get(`/pabx_login_info/${getHashEmpresa()}`);
    }
  }

  async function infoAgent3CPlus() {
    if (authUserInfo.disc_gateway == '3CPlus') {
      if (authUserInfo.ramal) {
        const response = await api.get(`/pabx_dados_agente`);
        const data = response.data.data;
        let ramal = '';
        ramal = data.extension.extension_number;
        ramal3CPlusRef.current = ramal;
        setRamalTopo(ramal);
        const idagente = data.id;
        setIdAgente(idagente);
      }
    }
  }

  async function handleQualificaLigacao(bodyData) {
    await api.post(`/qualificar_chamada_3cplus/${getHashEmpresa()}`, bodyData);
  }

  async function sairModoManualAgente3CPlus() {
    await api.post(`/pabx_sair_modo_manual`);
  }

  // ********************************************************************************************************************
  // EXPERTVOICE

  async function conectar_expertvoice() {
    let ramal = '';
    if (authUserInfo != null) ramal = authUserInfo.ramal;

    let url = '';
    if (authUserInfo != null) {
      url = authUserInfo.pabx_url;
      url = url.replace('http://', 'ws://');
      url = url.replace('https://', 'wss://');
    }

    if (ramal != '' && url != '') {
      try {
        const client = new WebSocket(url);
        clientRef.current = client;
        window.client = client;
      } catch (error) {
        console.log('Erro WebSocket!!! ', error);
      }
    }
  }

  // monitor de estados
  useEffect(() => {
    if (waitingToReconnect) {
      return;
    }

    if (clientRef.current && authUserInfo.disc_gateway == 'ExpertVoice') {
      clientRef.current.onerror = (e) => {
        console.error(e);
        setInicializarDiscador(false);
      };

      clientRef.current.onopen = () => {
        //console.log('WebSocket Opened');
        clientRef.current.send('ping');
        setInicializarDiscador(true);
      };

      clientRef.current.onclose = () => {
        if (clientRef.current) {
          console.log('WebSocket closed by server');
        } else {
          console.log('WebSocket closed by app component unmount');
          return;
        }

        if (waitingToReconnect) {
          return;
        }

        // setIsOpen(false);
        //console.log('WebSocket closed');

        setWaitingToReconnect(true);
        setTimeout(() => setWaitingToReconnect(null), 5000);
      };

      clientRef.current.onmessage = (message) => {
        //console.log('WebSocket Received: ', message);

        const retorno = message.data;

        const pos_evento_pausa = retorno.substring(0, 12); // ResultPausar
        const pos_evento_despausa = retorno.substring(0, 15); // ResultDespausar
        const pos_evento_logar = retorno.substring(0, 11); // ResultLogar
        const pos_evento_deslogar = retorno.substring(0, 14); // ResultDeslogar
        const pos_evento_discar = retorno.substring(0, 16); // RESPFAZERCHAMADA
        const pos_evento_retorno_chamada = retorno.substring(0, 12); // DadosChamada
        // FIMCHAMADA

        if (pos_evento_pausa == 'ResultPausar') {
          const retorno_array = retorno
            .replace(pos_evento_pausa, '')
            .replace('(', '')
            .replace(')', '')
            .split(';');

          const cod_retorno = retorno_array[2];
          if (cod_retorno >= 0) {
            setPausado(true);
            setStatusAgente('PAUSADO');

            if (jaPausado == 0) {
              jaPausado = 1;
              cadastrar_pausa_expert('Pausa');
            }
          }
        } else if (pos_evento_despausa == 'ResultDespausar') {
          const retorno_array = retorno
            .replace(pos_evento_despausa, '')
            .replace('(', '')
            .replace(')', '')
            .split(';');

          const cod_retorno = retorno_array[2];
          if (cod_retorno > 0) {
            setPausado(false);
            setStatusAgente('ONLINE');

            if (jaPausado == 1) {
              jaPausado = 0;
              cadastrar_pausa_expert('Despausa');
            }
          }
        } else if (pos_evento_logar == 'ResultLogar') {
          const retorno_array = retorno
            .replace(pos_evento_logar, '')
            .replace('(', '')
            .replace(')', '')
            .split(';');

          const cod_retorno = retorno_array[2];
          const desc_retorno = retorno_array[3];
          if (cod_retorno != 1) {
            setAtivaDiscador(false);

            setStatusAgente('OFFLINE');
            setLogado(false);
            setMsgStatusAgente(desc_retorno);
          } else {
            setStatusAgente('ONLINE');
            setLogado(true);
            setMsgStatusAgente('');
          }
        } else if (pos_evento_deslogar == 'ResultDeslogar') {
          const retorno_array = retorno
            .replace(pos_evento_deslogar, '')
            .replace('(', '')
            .replace(')', '')
            .split(';');
          const cod_retorno = retorno_array[2];
          if (cod_retorno == 1) {
            setAtivaDiscador(false);

            setStatusAgente('OFFLINE');
            setLogado(false);
            setMsgStatusAgente('');
          }
        } else if (pos_evento_discar == 'RESPFAZERCHAMADA') {
          const retorno_array = retorno
            .replace(pos_evento_discar, '')
            .replace('(', '')
            .replace(')', '')
            .split(';');
          // ainda não utilizado
          setAlertInfo('Discando...');
          setAlertInfoTipo('mb-3 bg-success');
          setOpenAlert(true);
        } else if (pos_evento_retorno_chamada == 'DadosChamada') {
          const retorno_array = retorno
            .replace(pos_evento_retorno_chamada, '')
            .replace('(', '')
            .replace(')', '')
            .split(';');

          let retorno_processo = retorno_array[3];
          const retorno_contato = retorno_array[9];
          const retorno_fone = retorno_array[2];
          const retorno_callid = retorno_array[12];
          const retorno_idgrupo = retorno_array[4];

          if (retorno_processo) {
            retorno_processo = retorno_processo.replace('-', '/');

            if (retorno_processo.indexOf('.') > 0)
              retorno_processo = retorno_processo.substring(
                0,
                retorno_processo.indexOf('.')
              );
          }

          setRetornoProcesso(retorno_processo);
          setRetornoDevedor(retorno_contato);
          setRetornoTelefone(retorno_fone);

          if (retorno_processo && retorno_callid)
            localizarProcesso(
              retorno_callid,
              retorno_processo,
              retorno_fone,
              retorno_idgrupo
            );
        }
      };

      return () => {
        //console.log('WebSocket Cleanup');
        // clientRef.current.close();
        clientRef.current = null;
      };
    }
  }, [waitingToReconnect]);

  async function loginAgenteExpertVoice() {
    let ramal = '';
    let senha = '';
    const dac = '';
    if (authUserInfo) {
      ramal = authUserInfo.ramal;
      senha = authUserInfo.ramal2;
    }

    if (!ramal && !senha) return;

    if (clientRef.current != null)
      clientRef.current.send(`LOGAR(${ramal};${senha};${dac})`);
  }

  async function deslogarAgenteExpertVoice() {
    let ramal = '';
    const dac = '';
    if (authUserInfo) ramal = authUserInfo.ramal;

    if (logado) clientRef.current.send(`DESLOGAR(${ramal};${dac})`);
  }

  async function cadastrar_pausa_expert(tipo) {
    const idPausa = getValues('tx_pausa');

    const hashEmpresa = getHashEmpresa();
    const data = {};
    data.colaborador = authUserInfo?.ac_login;
    data.motivo = '';
    data.id_uuid_pausa = idPausa || '';
    data.tipo = tipo;

    const data2 = JSON.stringify(data, null, 2);
    await api.post(`/pausa_usuario/${hashEmpresa}`, {
      data2,
    });
  }

  // pausa
  async function pausarAgenteExpertVoice() {
    let ramal = '';
    const dac = '';
    let usuario = '';
    if (authUserInfo) {
      ramal = authUserInfo.ramal;
      usuario = authUserInfo.ac_login;
    }

    const idPausa = getValues('tx_pausa');
    let pausaSelecionada = {};
    let codPausa = '';
    let descPausa = '';
    if (idPausa != '' && idPausa != null) {
      pausaSelecionada = listaMotivosPausa.find((el) => el.id_uuid == idPausa);
      if (pausaSelecionada) {
        codPausa = pausaSelecionada.codigo;
        descPausa = pausaSelecionada.descricao;
      }
    }

    if (codPausa != '') {
      if (clientRef.current != null)
        clientRef.current.send(
          `PAUSAR(${ramal};${dac};${codPausa};${descPausa})`
        );
    }
  }

  // despausa
  async function despausarAgenteExpertVoice() {
    let ramal = '';
    const dac = '';
    if (authUserInfo) {
      ramal = authUserInfo.ramal;
    }

    if (ramal != '') clientRef.current.send(`DESPAUSAR(${ramal};${dac})`);
  }

  // ********************************************************************************************************************
  // JDN

  async function conectar_jdn() {
    setInicializarDiscador(true);
    listarCampanhasAtivasJDN();
  }

  // monitor de estados
  /* NÃO EXISTE - ABERTURA É MANUAL PELO BOTÃO*/

  async function listarCampanhasAtivasJDN() {
    const response = await api.get(
      `/pabx_listar_campanhas_ativas/${getHashEmpresa()}`
    );
    if (response.status <= 400) {
      setListaCampanhas(response.data.rows);
    }
  }

  async function logarCampanhaJDN() {
    const nomeCampanha = getValues('tx_campanhas_ativas');

    if (!nomeCampanha) return;

    let campanhaSelecionada = {};
    let idCampanha = '';

    if (nomeCampanha != '' && nomeCampanha != null) {
      campanhaSelecionada = listaCampanhas.find((el) => el.id == nomeCampanha);
      if (campanhaSelecionada) idCampanha = campanhaSelecionada.notification;
    }

    let ramal = '';
    if (authUserInfo) ramal = authUserInfo.ramal;

    const response = await api.post(
      `/pabx_login/${getHashEmpresa()}?ramal=${ramal}&campanha=${idCampanha}`
    );
    if (response.status <= 400) {
      const statusLogin = response.data.Status;
      if (statusLogin != 'Erro') {
        setLogado(true);
        setStatusAgente('ONLINE');

        setRetornoCampanhaLogadaNome(campanhaSelecionada.name);
      }
    }
  }

  async function deslogarCampanhaJDN() {
    let ramal = '';
    if (authUserInfo) ramal = authUserInfo.ramal;

    const response = await api.post(
      `/pabx_logoff/${getHashEmpresa()}?ramal=${ramal}&campanha=${retornoCampanhaLogadaId}`
    );
    if (response.status <= 400) {
      setLogado(false);
      setPausado(false);
      setBloqueiaComboCampanha(false);
      setStatusAgente('');

      setRetornoCampanhaLogadaNome('Nao Logado');
      setRetornoCampanhaLogadaId('');
    }
  }

  async function retornoProcessoJDN() {
    let ramal = '';
    if (authUserInfo) ramal = authUserInfo.ramal;
    setCallfilename('');

    const response = await api.get(
      `/pabx_login_info_processo/${getHashEmpresa()}?ramal=${ramal}`
    );
    if (response.status <= 400) {
      const data = response.data.rows;
      if (data.length > 0) {
        const retorno_processo = data[0].Identificacao;
        const retorno_contato = data[0].Contato;
        const retorno_fone = data[0].Telefone;
        const retorno_callfilename = data[0].Userfield;
        setCallfilename(retorno_callfilename);

        setRetornoDevedor(retorno_contato);
        setRetornoProcesso(retorno_processo);
        setRetornoTelefone(retorno_fone);
        // setValue('tx_retorno_processo', retorno_processo);
        // setValue('tx_retorno_contato', retorno_contato);
        // setValue('tx_retorno_fone', retorno_fone);
      }
    }
  }

  async function infoLoginJDN() {
    let ramal = '';
    if (authUserInfo) ramal = authUserInfo.ramal;

    if (!ramal) return;

    const response = await api.get(
      `/pabx_login_info/${getHashEmpresa()}?ramal=${ramal}`
    );
    if (response.status <= 400) {
      const data = response.data.rows;
      if (data.length > 0) {
        const campanha_logada = data[0].Campanha;
        const canal = data[0].Fila || data[0].Canal;

        setRetornoCampanhaLogadaNome(campanha_logada);
        setRetornoCampanhaLogadaId(canal);

        if (campanha_logada != 'Nao Logado' && campanha_logada != 'Offline') {
          setLogado(true);
          setStatusAgente('ONLINE');
        }
        if (canal == 'Pausado') {
          setPausado(true);
          setStatusAgente('PAUSADO');
        }
      }
    }
  }

  //pausa
  async function pausarAgenteJDN() {
    let ramal = '';
    let usuario = '';
    if (authUserInfo) {
      ramal = authUserInfo.ramal;
      usuario = authUserInfo.ac_login;
    }

    const idPausa = getValues('tx_pausa');
    let pausaSelecionada = {};
    let codPausa = '';
    let descPausa = '';
    if (idPausa != '' && idPausa != null) {
      pausaSelecionada = listaMotivosPausa.find((el) => el.id_uuid == idPausa);
      if (pausaSelecionada) {
        codPausa = pausaSelecionada.codigo;
        descPausa = pausaSelecionada.descricao;
      }
    }

    if (codPausa != '') {
      const response = await api.get(
        `/pabx_pausa/${getHashEmpresa()}?ramal=${ramal}&usuario=${usuario}&pausa=${codPausa}&descpausa=${descPausa}`
      );
      if (response.status <= 400) {
        const { data } = response;
        if (data.Response == 'Success') {
          setPausado(true);
          setStatusAgente('PAUSADO');
        }
      }
    }
  }

  //despausa
  async function despausarAgenteJDN() {
    let ramal = '';
    let usuario = '';
    if (authUserInfo) {
      ramal = authUserInfo.ramal;
      usuario = authUserInfo.ac_login;
    }

    const response = await api.get(
      `/pabx_despausa/${getHashEmpresa()}?ramal=${ramal}&usuario=${usuario}`
    );
    if (response.status <= 400) {
      const { data } = response;
      if (data.Response == 'Success') {
        setPausado(false);
        setStatusAgente('ONLINE');
      }
    }
  }

  // ********************************************************************************************************************
  // OLOS
  async function conectar_olos() {
    if (authUserInfo) {
      let url = '';
      if (authUserInfo.pabx_empresa === 'Olos') url = authUserInfo.pabx_url;
      else if (authUserInfo.pabx2_empresa === 'Olos')
        url = authUserInfo.pabx2_url;

      if (!url) return;

      const addrs = {
        wsAgentCmd: `${url}/WebAPIAgentControl/AgentCommand/`,
        wsAgentEvt: `${url}/WebAPIAgentControl/AgentEvent/`,
        WsAgentCloud: `${url}/WebAPIAgentControl/CloudAgent/`,
        wsMailingCmd: `${url}/WebAPIMailingControl/MailingCommand/`,
        wsAgentConfig: `${url}/WebAPIConfiguration/AgentConfig/`,
        wsVoiceSupport: `${url}/WsVoiceSupportIntegration/WsVoiceSupportIntegration.asmx`,
        WebAPIAgentControl: `${url}/WebAPIAgentControl/`,
        wsSoftphone: `${url}/WebAPISoftphone/Softphone/`,
        wsMcx: `${url}/WsMcx/wsmcx/Mcx/`,
        wsRecordingRetrieve: `${url}/WebApiRecordingRetrieve/RecordTextComm/`,
      };

      /*
      // auth testes
      const auth = {
        user: 'ext_api_sys',
        password: '0los_@pi',
        clientID: 'e9b9383e437b4bf284553c2f8af3ea82',
        clientSecret: 'MCZ0mUMHJp7ZL0bTGbY_FS8jQqhpH9mHFDmPP9jd8TQ',
      };
      */

      const auth = {
        user: 'api_token',
        password: 'olos@123',
        clientID: 'c09b706a468a4414924fd0402f52099f',
        clientSecret: 'i7l6_WVuYGHDk-bcHDr1weYUC3_z8A3N9rPJg22Tbbw',
      };

      const jsLogger = false;
      createOlos(addrs, auth, jsLogger);

      const client = new OlosAgentWS();
      clientRef.current = client;
      window.client = client;
    }
  }

  // monitor de estados
  useEffect(() => {
    if (waitingToReconnectOlos) {
      return;
    }

    if (clientRef.current && authUserInfo.disc_gateway == 'Olos') {
      console.log('eventos olos');

      // responsavel por mostrar o ramal no topo
      const handleAgentAuthentication = (ramal) => {
        setRamalTopo(ramal);
        setInicializarDiscador(true);
      };

      const handleLogger = async (event) => {
        //console.log('Olos handleLogger...', event);
      };
      /*
      const handleNothing = async (event) => {
        console.log('Olos handleNothing...', event);
      };
      */

      // responsavel por monitorar o status do agente
      const handleChangestatus = async (event) => {
        //console.log('Olos handleChangestatus...', event);
        const agenteStatus = event.agentStatusId;
        console.log('Olos handleChangestatusId...', agenteStatus);

        if (agenteStatus === 'Pause') {
          setMsgStatusAgente('');
          setStatusAgente('PAUSADO');
          setPausado(true);
        } else if (agenteStatus === 'Idle') {
          setMsgStatusAgente('');
          setStatusAgente('ONLINE');
          setPausado(false);
          setCorBotao('green');
          setIsModoManual(false);
          setIsModoTabulando(false);
          window.discador_modo_manual = false;
          window.discador_id_chamada = null;
        } else if (agenteStatus === 'Ending') {
          setMsgStatusAgente('');
          setPausado(false);
          setStatusAgente('OFFLINE');
          setLogado(false);
          window.discador_id_chamada = null;
        } else if (agenteStatus === 'ManualCall') {
          setMsgStatusAgente('Modo Manual');
          setStatusAgente('ONLINE');
          setCorBotao('yellow');
          setIsModoManual(true);
          window.discador_modo_manual = true;
        } else if (agenteStatus === 'Talking') {
          setMsgStatusAgente('Em Andamento');
          setStatusAgente('ONLINE');
          setCorBotao('blue');
        } else if (agenteStatus === 'TalkingWithPause') {
          setMsgStatusAgente('Em Andamento c/ Pausa');
          setCorBotao('blue');
        } else if (agenteStatus === 'Wrap') {
          setMsgStatusAgente('Em Tabulação');
          setCorBotao('blue');
          setIsModoTabulando(true);
        } else if (agenteStatus === 'WrapWithPause') {
          setMsgStatusAgente('Em Tabulação c/ Pausa');
          setCorBotao('blue');
          setIsModoTabulando(true);
        }
      };

      const handleChangeStatusFail = async (event) => {
        console.log('Olos handleChangeStatusFail...', event);
      };

      const handleLogoutReason = async (event) => {
        //console.log('Olos handleLogoutReason...', event);
      };

      const handlePasscode = async (passcode) => {
        setMsgStatusAgente(`PassCode: ${passcode}`);
        console.log('Olos handlePasscode...', passcode);
      };

      const handleLoginCCM = async (event) => {
        setMsgStatusAgente('');
        setRamalTopo(event.agentId);

        setLogado(true);
        setAtivaDiscador(true);
        console.log('Olos handleLoginCCM...', event);
      };

      const handleLoginCCMFail = async (event) => {
        console.log('Olos handleLoginCCMFail...', event);
      };

      const handleLogoutCCM = async (event) => {
        setMsgStatusAgente('');
        setRamalTopo('');

        setLogado(false);
        setAtivaDiscador(false);

        setPausado(false);
        setStatusAgente('OFFLINE');

        //console.log('Olos handleLogoutCCM...', event);
      };

      const handleLoginCampaign = async (event) => {
        if ((event.campaignName || '').toUpperCase().includes('MANUAL')) {
          setCampanhaId(event.campaignId);
          window.discador_campanha_id = event.campaignId;
        }
        console.log('Olos handleLoginCampaign...', event);
      };

      const handleLoginCampaignFail = async (event) => {
        console.log('Olos handleLoginCampaignFail...', event);
      };
      const handleLogoutCampaign = async (event) => {
        console.log('Olos handleLogoutCampaign...', event);
      };

      const handleManualOnlineCampaignChangeStatusId = async (event) => {
        console.log('Olos handleManualCallRequestFail...', event);
      };

      const handleManualCallRequestFail = async (event) => {
        console.log('Olos handleManualCallRequestFail...', event);

        setMsgStatusAgente('');
        setStatusAgente('ONLINE');
        setCorBotao('green');
        setIsModoManual(false);
        window.discador_modo_manual = false;
      };

      const handleChangeManualCallState = async (event) => {
        console.log('Olos handleChangeManualCallState...', event);

        callIdRef.current = event.callId;
        window.discador_id_chamada = event.callId;
        if (event.callState == 'Started') {
          setMsgStatusAgente('Discando - Manual');
        } else if (event.callState == 'CustomerConnected') {
          setMsgStatusAgente('Em Andamento - Manual');
        } else if (event.callState == 'Finished') {
          setMsgStatusAgente('');
        }
      };

      const handleScreenpop = async (event) => {
        //console.log('Olos handleScreenpop...', event);

        callIdRef.current = event.callId;
        window.discador_id_chamada = event.callId;

        const strMaillingData = event.campaignData;
        const regex = /<DEVEDOR>(.*?)<\/DEVEDOR>/;
        const match = strMaillingData.match(regex);

        const retorno_contato = match ? match[1] : '';
        setRetornoProcesso(event.customerId);
        setRetornoDevedor(retorno_contato);
        setRetornoTelefone(event.phoneNumber);

        if (event.customerId && event.callId)
          localizarProcesso(
            event.callId,
            event.customerId,
            event.phoneNumber,
            ''
          );
      };

      olosOn('AgentAuthentication', handleAgentAuthentication);
      //olosOn('Nothing', handleNothing);
      olosOn('logger', handleLogger);
      olosOn('Changestatus', handleChangestatus);
      olosOn('ChangeStatusFail', handleChangeStatusFail);
      olosOn('AgentLogout', handleLogoutReason);
      olosOn('Passcode', handlePasscode);
      olosOn('LoginCCM', handleLoginCCM);
      olosOn('LoginCCMFail', handleLoginCCMFail);
      olosOn('LogoutCCM', handleLogoutCCM);
      olosOn('LoginCampaign', handleLoginCampaign);
      olosOn('LoginCampaignFail', handleLoginCampaignFail);
      olosOn('LogoutCampaign', handleLogoutCampaign);
      olosOn('ChangeManualCallState', handleChangeManualCallState);
      olosOn('ManualCallRequestFail', handleManualCallRequestFail);
      olosOn(
        'OnlineCampaignChangeStatusId',
        handleManualOnlineCampaignChangeStatusId
      );
      olosOn('Screenpop', handleScreenpop);

      return () => {
        if (clientRef.current) {
          olosOff('AgentAuthentication', handleAgentAuthentication);
          //olosOff('Nothing', handleNothing);
          olosOff('logger', handleLogger);
          olosOff('Changestatus', handleChangestatus);
          olosOff('ChangeStatusFail', handleChangeStatusFail);
          olosOff('AgentLogout', handleLogoutReason);
          olosOff('Passcode', handlePasscode);
          olosOff('LoginCCM', handleLoginCCM);
          olosOff('LoginCCMFail', handleLoginCCMFail);
          olosOff('LogoutCCM', handleLogoutCCM);
          olosOff('LoginCampaign', handleLoginCampaign);
          olosOff('LoginCampaignFail', handleLoginCampaignFail);
          olosOff('LogoutCampaign', handleLogoutCampaign);
          olosOff('ManualCallRequestFail', handleManualCallRequestFail);
          olosOff('ChangeManualCallState', handleChangeManualCallState);
          olosOff(
            'OnlineCampaignChangeStatusId',
            handleManualOnlineCampaignChangeStatusId
          );
          olosOff('Screenpop', handleScreenpop);
        }

        //clientRef.current = null;
      };
    }
  }, [waitingToReconnectOlos]);

  function logarOlos() {
    //if (clientRef.current && (clientRef.current._worker == false || !logado)) {
    if (!logado) {
      if (!authUserInfo) return;

      const loginOlos = authUserInfo.ramal;
      const senhaOlos = authUserInfo.ramal2;

      //console.log(loginOlos, senhaOlos);

      if (!loginOlos && !senhaOlos) return;

      clientRef.current.agentAuthentication(loginOlos, senhaOlos);
    }
  }

  function deslogarOlos() {
    if (logado) clientRef.current.agentLogout();
    //terminateWorker();
  }

  function modoLivreOlos() {
    clientRef.current.dispositionCallByCode(null);
  }

  function testeOlos() {
    console.log('Teste 01');
    setWaitingToReconnectOlos(true);
    setTimeout(() => setWaitingToReconnectOlos(null), 5000);
    // clientRef.current.manualCallStateRequest();
    // clientRef.current.handleNextEvent();
    // clientRef.current.listAvailableOnlineAgents();
    // clientRef.current.agentDailySummary(); //produção diaria
    // clientRef.current.sendManualCallRequest('48', '36323810', '491');
    // clientRef.current.listReasons(); //lista pausas
    // clientRef.current.listDispositions(); //lista tabulações
    // clientRef.current.dispositionCall('155'); //tabular chamada
  }
  function testeOlos2() {
    console.log('Teste 02');
    clientRef.current.handleNextEvent();
  }
  function testeOlos3() {
    console.log('Teste 03');
    console.log(clientRef.current);
  }
  function testeOlos4() {
    console.log('Teste 04');
    terminateWorker();
  }
  function testeOlos5() {
    console.log('Teste 05');
    conectar_olos();
    //window.location.reload();
  }

  // pausa
  async function pausarAgenteOlos() {
    const idPausa = getValues('tx_pausa');
    let pausaSelecionada = {};
    let codPausa = '';
    if (idPausa) {
      pausaSelecionada = listaMotivosPausa.find((el) => el.id_uuid == idPausa);
      if (pausaSelecionada) codPausa = pausaSelecionada.codigo;
    }
    if (codPausa) {
      clientRef.current.agentReasonRequest(codPausa);
    }
  }

  // despausa
  async function despausarAgenteOlos() {
    clientRef.current.agentIdleRequest();
  }

  async function sairModoManualAgenteOlos() {
    clientRef.current.endManualCallStateRequest();
  }

  const handleClickCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <div className={`theme-option bg-${ativaDiscador ? corBotao : 'grey'}`}>
      <IconButton onClick={toggleCustomizer}>
        {pausado ? (
          <PhonePausedIcon fontSize="small" style={{ color: '#fff' }} />
        ) : (
          <PhoneIcon fontSize="small" style={{ color: '#fff' }} />
        )}
      </IconButton>
      <Drawer
        className="app-sidebar-content right-sidebar"
        anchor="right"
        open={drawerStatus}
        onClose={closeCustomizer}
      >
        <div style={{ maxWidth: '300px' }}>
          <div className="color-theme">
            <div className="color-theme-header">
              <h3 className="color-theme-title">DISCADOR / R: {ramalTopo}</h3>

              <IconButton className="icon-btn" onClick={closeCustomizer}>
                <i className="zmdi zmdi-close text-white" />
              </IconButton>
            </div>
          </div>

          <div className="row pt-2 pl-1 pr-1 m-0">
            <div className="col-12">
              <Box display="flex">
                <Typography component="div">
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <GreenSwitch
                        disabled={!inicializarDiscador}
                        checked={ativaDiscador}
                        onChange={(e) => setAtivaDiscador(e.target.checked)}
                        name="ativar Discador"
                      />
                    </Grid>
                    <Grid item>
                      {ativaDiscador == true
                        ? 'Discador Ativo'
                        : 'Discador Inativo'}
                    </Grid>
                  </Grid>
                </Typography>
              </Box>
            </div>
          </div>

          <div
            hidden={
              authUserInfo.disc_gateway != 'Vonix' &&
              authUserInfo.disc_gateway != 'ExpertVoice' &&
              authUserInfo.disc_gateway != '3CPlus' &&
              authUserInfo.disc_gateway != 'Olos'
            }
            className="mb-2"
          >
            <div className="row pt-2 pl-1 pr-1 m-0">
              <div className="col-12">
                <TextField
                  disabled={!inicializarDiscador}
                  autoComplete="off"
                  id="tx_fone"
                  name="tx_fone"
                  size="small"
                  label="Telefone"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ fontSize: '12px' }}
                  variant="outlined"
                  fullWidth
                  inputRef={register}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') discar();
                  }}
                />
              </div>
            </div>

            <div className="row pt-2 pl-1 pr-1 m-0">
              <div className="col-6 pr-0">
                <Button
                  disabled={!inicializarDiscador}
                  variant="contained"
                  className={
                    inicializarDiscador
                      ? 'jr-btn-sm bg-success text-white'
                      : 'jr-btn-sm bg-disabled text-white'
                  }
                  fullWidth
                  onClick={discar}
                >
                  Ligar
                </Button>
              </div>
              <div className="col-6">
                <Button
                  disabled={!inicializarDiscador}
                  variant="contained"
                  color="secondary"
                  className={
                    inicializarDiscador
                      ? 'jr-btn-sm bg-danger text-white'
                      : 'jr-btn-sm bg-disabled text-white'
                  }
                  fullWidth
                  onClick={derrubar}
                >
                  Desligar
                </Button>
              </div>
            </div>
          </div>

          <div
            hidden={
              authUserInfo.disc_gateway != 'JDN' &&
              authUserInfo.disc_gateway != '3CPlus'
            }
          >
            <div className="row pt-2 pl-1 pr-1 m-0">
              <div className="col-12">
                <TextField
                  size="small"
                  id="tx_campanha_logada"
                  name="tx_campanha_logada"
                  label="Campanha Logada"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{ fontSize: '12px' }}
                  variant="outlined"
                  fullWidth
                  inputRef={register}
                  value={retornoCampanhaLogadaNome}
                />
              </div>
            </div>

            <div className="row pt-2 pl-1 pr-1 m-0">
              <div className="col-12">
                <TextField
                  select
                  id="tx_campanhas_ativas"
                  name="tx_campanhas_ativas"
                  size="small"
                  label="Campanhas"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  SelectProps={{
                    native: true,
                  }}
                  style={{ fontSize: '12px' }}
                  variant="outlined"
                  fullWidth
                  inputRef={register}
                  disabled={!ativaDiscador || bloqueiaComboCampanha}
                >
                  <option value="" />
                  {listaCampanhas.map((item) =>
                    authUserInfo.disc_gateway == '3CPlus' ? (
                      <option key={item.campanha_id} value={item.campanha_id}>
                        {item.campanha_nome}
                      </option>
                    ) : (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    )
                  )}
                </TextField>
              </div>
            </div>

            <div className="row pt-2 pl-1 pr-1 m-0">
              <div className="col-6 pr-0">
                <Button
                  variant="outlined"
                  color="primary"
                  className="jr-btn-sm"
                  fullWidth
                  onClick={handleLogarCampanha}
                  disabled={!ativaDiscador || logado}
                >
                  Logar
                </Button>
              </div>
              <div className="col-6">
                <Button
                  variant="outlined"
                  color="secondary"
                  className="jr-btn-sm"
                  fullWidth
                  onClick={deslogarCampanhaJDN}
                  disabled={!ativaDiscador || !logado}
                >
                  Deslogar
                </Button>
              </div>
            </div>
          </div>

          <Divider className="m-0 mt-3 mb-2" />

          <div className="row pt-2 pl-1 pr-1 m-0">
            <div className="col-12">
              <TextField
                disabled={!inicializarDiscador || pausado}
                select
                id="tx_pausa"
                name="tx_pausa"
                size="small"
                label="Motivo de Pausa"
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  native: true,
                }}
                style={{ fontSize: '12px' }}
                variant="outlined"
                fullWidth
                inputRef={register}
              >
                <option key={null} value="" />
                {listaMotivosPausa.map((item) => (
                  <option key={item.id_uuid} value={item.id_uuid}>
                    {item.descricao}
                  </option>
                ))}
              </TextField>
            </div>
          </div>

          <div className="row pt-2 pl-1 pr-1 m-0">
            <div className="col-6 pr-0">
              <Button
                variant="outlined"
                color="secondary"
                className="jr-btn-sm"
                fullWidth
                onClick={pausarAgente}
                disabled={
                  pausado || !inicializarDiscador || isModoManual || loading
                }
              >
                <PauseIcon />
                Pausar
              </Button>
            </div>
            <div className="col-6">
              <Button
                variant="outlined"
                color="primary"
                className="jr-btn-sm"
                fullWidth
                onClick={despausarAgente}
                disabled={
                  ((!pausado || !inicializarDiscador) && !isModoManual) ||
                  loading
                }
              >
                <PlayArrowIcon />
                Despausar
              </Button>
            </div>
          </div>

          {getHashEmpresa() == 'mt93ol' ||
            (getHashEmpresa() == 'lt93cb' && (
              <div
                className="row pt-2 pl-1 pr-1 m-0"
                hidden={authUserInfo.disc_gateway != 'Vonix'}
              >
                <div className="col-12">
                  <Button
                    variant="contained"
                    color="secondary"
                    className="jr-btn-sm bg-warning text-white"
                    fullWidth
                    onClick={pausarAgenteAcionamentoVonix}
                    disabled={pausado || !inicializarDiscador}
                  >
                    <PauseIcon />
                    Pausa Acionamento
                  </Button>
                </div>
              </div>
            ))}

          <Divider className="m-0 mt-3 mb-2" />

          <div
            className="row pt-2 pb-2 pl-1 pr-1 m-0"
            hidden={authUserInfo.disc_gateway != 'JDN'}
          >
            <div className="col-12">
              <Button
                variant="outlined"
                className="jr-btn-sm"
                fullWidth
                onClick={retornoProcessoJDN}
              >
                Retorno Processo
              </Button>
            </div>
          </div>

          <div className="row pt-2 pl-1 pr-1 m-0">
            <div className="col-12">
              <TextField
                size="small"
                id="tx_retorno_contato"
                name="tx_retorno_contato"
                label="Devedor"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
                style={{ fontSize: '12px' }}
                variant="outlined"
                fullWidth
                value={retornoDevedor}
              />
            </div>
          </div>

          <div className="row pt-2 pl-1 pr-1 m-0">
            <div className="col-6">
              <TextField
                size="small"
                id="tx_retorno_processo"
                name="tx_retorno_processo"
                label="Processo"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
                style={{ fontSize: '12px' }}
                variant="outlined"
                fullWidth
                value={retornoProcesso}
              />
            </div>
            <div className="col-6 pl-0">
              <TextField
                size="small"
                id="tx_retorno_fone"
                name="tx_retorno_fone"
                label="Telefone"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
                style={{ fontSize: '12px' }}
                variant="outlined"
                fullWidth
                value={retornoTelefone}
              />
            </div>
          </div>

          <div className="row pt-2 pl-1 pr-1 m-0">
            <div className="col-12">
              <Button
                variant="contained"
                className="jr-btn-sm bg-info text-white"
                fullWidth
                onClick={localizarProcessoManual}
              >
                Abrir Processo
              </Button>
            </div>
          </div>

          {authUserInfo?.disc_gateway == 'Olos' && (
            <>
              <Divider className="m-0 mt-3 mb-2" />

              <div className="row pt-2 pl-1 pr-1 m-0">
                <div className="col-6 pr-1">
                  <Button
                    variant="contained"
                    className="jr-btn-sm bg-warning text-white"
                    fullWidth
                    onClick={logarOlos}
                  >
                    Conectar
                  </Button>
                </div>

                <div className="col-6 pl-1">
                  <Button
                    variant="contained"
                    className="jr-btn-sm bg-warning text-white"
                    fullWidth
                    onClick={deslogarOlos}
                  >
                    Desconectar
                  </Button>
                </div>
              </div>

              <div className="row pt-2 pl-1 pr-1 m-0">
                <div className="col-12">
                  <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    onClick={abrirMenuMaisOpcoes}
                    className="jr-btn-sm bg-disabled text-white"
                  >
                    Ações (Testes)
                  </Button>

                  <StyledMenu
                    style={{ zIndex: 1600 }}
                    keepMounted
                    id="fade-menu"
                    anchorEl={anchorEl}
                    TransitionComponent={Fade}
                    open={Boolean(anchorEl)}
                    onClose={fecharMenuMaisOpcoes}
                  >
                    <MenuItem onClick={testeOlos}>
                      <ListItemText
                        classes={{ primary: classes.listItemText }}
                        primary="Teste 01"
                      />
                    </MenuItem>
                    <MenuItem onClick={testeOlos2}>
                      <ListItemText
                        classes={{ primary: classes.listItemText }}
                        primary="Teste 02"
                      />
                    </MenuItem>
                    <MenuItem onClick={testeOlos3}>
                      <ListItemText
                        classes={{ primary: classes.listItemText }}
                        primary="Teste 03"
                      />
                    </MenuItem>
                    {/*
                    <MenuItem onClick={testeOlos4}>
                      <ListItemText
                        classes={{ primary: classes.listItemText }}
                        primary="Teste 04"
                      />
                    </MenuItem>
                    <MenuItem onClick={testeOlos5}>
                      <ListItemText
                        classes={{ primary: classes.listItemText }}
                        primary="Teste 05"
                      />
                    </MenuItem>
                    */}
                  </StyledMenu>
                </div>
              </div>

              <div className="row pt-2 pl-1 pr-1 m-0" hidden={!isModoTabulando}>
                <div className="col-12">
                  <Button
                    variant="contained"
                    className="jr-btn-sm bg-blue text-white"
                    fullWidth
                    onClick={modoLivreOlos}
                  >
                    Modo Livre
                  </Button>
                </div>
              </div>
            </>
          )}

          <div className="row pt-2 pl-1 pr-1 m-0">
            <div className="col-12 p-0 pl-2 pr-2 text-center">
              <Typography variant="h6" className={corStatusAgente}>
                {statusAgente} {counter > 0 && horaPausa()}
              </Typography>
              <Typography
                variant="body2"
                className="text-danger"
                hidden={!msgStatusAgente}
              >
                {msgStatusAgente}
              </Typography>
            </div>
          </div>

          {ramalRodape && (
            <div className="row pt-2 pl-1 pr-1 m-0">
              <div className="col-12 p-0 pl-2 pr-2 text-center">
                <Typography variant="body2">Ramal: {ramalRodape}</Typography>
              </div>
            </div>
          )}

          <div className="row pt-2 pl-1 pr-1 m-0" hidden={inicializarDiscador}>
            <div className="col-12 p-0 pl-2 pr-2 text-center">
              <Typography variant="h6" className="text-danger">
                DESABILITADO
              </Typography>
              <Typography variant="body2" className="text-danger">
                Sem conexão com o discador !!!
              </Typography>
            </div>
          </div>
        </div>
      </Drawer>

      {/** notificação */}
      <SnackbarNotification
        openAlert={openAlert}
        alertInfoTipo={alertInfoTipo}
        alertInfo={alertInfo}
        callbackParent={() => handleClickCloseAlert()}
      />
    </div>
  );
};

export default withRouter(PainelDiscador);
